<template>
  <div class="px-40 border border-EEEEEE py-35 purseBox">
    <!-- 标签 -->
    <div class="flex align-center justify-between pb-20 border-bottom border-F4F4F4">
      <div class="font-20 text-333333">银行卡绑定</div>
      <div class="font-12 text-666666 flex align-center">
        目前所在：<el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/myIndex' }">个人中心</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/cardList' }">银行卡列表</el-breadcrumb-item>
          <el-breadcrumb-item>银行卡绑定</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!-- 内容 -->
    <div>
      <div class="py-25">
        <el-steps :active="active" finish-status="success">
          <el-step title="实名认证"></el-step>
          <el-step title="绑定银行卡"></el-step>
          <el-step title="绑卡验证"></el-step>
        </el-steps>
      </div>
      <div class="myIndex_info">
        <div class="flex align-center justify-between mb-10" v-if="active == 0">
          <div class="myIndex_info_title font-14 text-333333">真实姓名</div>
          <div>
            <el-input v-model="user_name" placeholder="请输入"></el-input>
          </div>
        </div>
        <div class="flex align-center justify-between mb-10" v-if="active == 0">
          <div class="myIndex_info_title font-14 text-333333">身份证号</div>
          <div>
            <el-input v-model="cert_id" placeholder="请输入"></el-input>
          </div>
        </div>
        <div class="flex align-center justify-between mb-10" v-if="active == 0">
          <div class="myIndex_info_title font-14 text-333333">手机号</div>
          <div>
            <el-input v-model="tel_no" placeholder="请输入"></el-input>
          </div>
        </div>
        <div class="flex align-center justify-between mb-10" v-if="active == 1">
          <div class="myIndex_info_title font-14 text-333333">卡号</div>
          <div>
            <el-input v-model="bank_card" placeholder="请输入"></el-input>
          </div>
        </div>
        <div class="flex align-center justify-between mb-10" v-if="active == 1">
          <div class="myIndex_info_title font-14 text-333333">银行预留手机号</div>
          <div>
            <el-input v-model="bank_mobile" placeholder="请输入"></el-input>
          </div>
        </div>
        <div class="flex align-center justify-between mb-10" v-if="active == 1">
          <div class="myIndex_info_title font-14 text-333333">信用卡验证码</div>
          <div>
            <el-input v-model="vip_code" placeholder="请输入"></el-input>
          </div>
        </div>
        <div class="flex align-center justify-between mb-10" v-if="active == 1">
          <div class="myIndex_info_title font-14 text-333333">信用卡有效期</div>
          <div>
            <el-input v-model="expiration" placeholder="请输入"></el-input>
          </div>
        </div>
        <div class="flex align-center justify-between mb-10" v-if="active == 2">
          <div class="myIndex_info_title font-14 text-333333">银行预留手机号验证码</div>
          <div>
            <el-input v-model="bank_card" placeholder="请输入"></el-input>
          </div>
        </div>
      </div>
      <div class="purseBox_btn" @click="nextstep" v-if="active == 0">下 一 步</div>
      <div class="purseBox_btn" @click="verification" v-if="active == 1">申 请 验 证</div>
      <div class="purseBox_btn" @click="bind" v-if="active == 2">确 定</div>
    </div>
  </div>
</template>

<script>
import req from "../../../utils/req";
export default {
  data() {
    return {
      tel_no: "", //用户手机号
      user_name: "", //用户姓名
      cert_id: "", //证件号
      bank_card: "", //银行卡卡号
      bank_mobile: "", //银行卡对应的预留手机号
      vip_code: "", //	信用卡验证码，若银行卡为信用卡时必填，银行卡背面签名条末三位
      expiration: "", //信用卡有效期，若银行卡为信用卡时必填
      sms_code: "", //短信验证码
      apply_id: "", //Adapay生成的快捷绑卡申请id
      active: 0,
    };
  },
  mounted() {},
  methods: {
    // 绑定银行卡验证
    bind() {
      let that = this;
      req
        .post("auth/bankCode", {
          sms_code: that.sms_code,
          apply_id: that.apply_id,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          that.$message.success(res.msg);
          that.$router.go(-1);
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
    // 绑定银行卡
    verification() {
      let that = this;
      req
        .post("auth/bankLogin", {
          bank_card: that.bank_card,
          bank_mobile: that.bank_mobile,
          vip_code: that.vip_code,
          expiration: that.expiration,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          that.$message.success(res.msg);
          that.apply_id = res.data.id;
          that.active = 2;
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
    // 实名认证
    nextstep() {
      let that = this;
      req
        .post("center/createUser", {
          tel_no: that.tel_no,
          user_name: that.user_name,
          cert_id: that.cert_id,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          that.$message.success(res.msg);
          that.active = 1;
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.purseBox {
  height: 100%;
  box-sizing: border-box;
  &_btn {
    width: 7.6875rem;
    height: 2.1875rem;
    line-height: 2.1875rem;
    text-align: center;
    font-size: 0.875rem;
    color: #ffffff;
    background: #ff524c;
    border-radius: 1.125rem;
    cursor: pointer;
    margin-left: 2.375rem;
  }
  ::v-deep .el-input__inner {
    -webkit-appearance: none;
    background-color: #fff;
    border-radius: 0;
    border: 0;
    box-sizing: border-box;
    color: #333333;
    display: inline-block;
    font-size: 0.875rem;
    height: 2.5rem;
    line-height: 2.5rem;
    outline: 0;
    text-align: right;
    padding: 0 0.9375rem;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
  }
  .myIndex {
    &_info {
      padding: 2.375rem 2.375rem 2rem;
      padding-right: 25rem;
      &_title {
        width: 11.25rem;
      }
    }
  }
}
</style>
